import { render, staticRenderFns } from "./Partniori.vue?vue&type=template&id=039a2a03&scoped=true&"
import script from "./Partniori.vue?vue&type=script&lang=js&"
export * from "./Partniori.vue?vue&type=script&lang=js&"
import style0 from "./Partniori.vue?vue&type=style&index=0&id=039a2a03&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "039a2a03",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCard,VCardText,VImg})
