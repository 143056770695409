<template>
  <div class="home">
    <div class="carousel-container">
      <v-carousel hide-delimiters cycle>
        <v-carousel-item
          v-for="(item, i) in carouselItems"
          :key="i"
          :src="item"
        ></v-carousel-item>
      </v-carousel>
    </div>
    <!--        <div class="divider"></div>-->
    <v-divider></v-divider>
    <div class="for-us pa-4 pl-8">
      <h1>ДК Терма ЕООД</h1>
      <p class="subtitle-1">
        ДK Терма ЕООД е с основна дейност доставка и монтаж на пелетни
        съоръжения, представител на най-качествените производители на пелетни
        системи за Пловдив и региона.
      </p>
      <p class="subtitle-1">
        Монтираме различни системи спрямо изискванията на клиентите и
        спецификата на обектите, което ни позволява да не сме ограничени в
        дистрибуцията на определени продукти. Това ни помага да изберем
        най-добрата инсталация за конкретния обект. Изпълняваме едновременно В и
        К, ОВК, поливна и слънчева система, което ни дава възможността да
        синхронизираме инсталациите и съчетаем добрия монтаж.
      </p>
      <h2 class="subtitle-1">
        ДК Терма ЕООД е генерален дистрибутор на
        <strong>Грейко инженеринг ООД</strong> и <strong>Виктория 05</strong>.
      </h2>
    </div>
    <v-divider></v-divider>
    <div class="offers-container">
      <offer-card
        v-for="(offer, index) in offers"
        :key="index"
        :description="offer.description"
        :sub-title="offer.subTitle"
        :src="offer.image"
        :title="offer.title"
        :name="offer.name"
        @read-more="openPage"
      ></offer-card>
    </div>
    <!--        <div class="divider"></div>-->
    <v-divider></v-divider>
    <div class="reecl pa-4 pl-8">
      <div class="text">
        <h3>
          ДК Терма е част от програмата за енергийна ефективност
          <strong>REECL</strong>
        </h3>
        <p>
          Програмата REECL предоставя на домакинства, сдружения на етажни
          собственици или фирми за услуги (професионални домоуправители, ЕСКО,
          предприемачи и строители) от цялата страна възможност да се възползват
          от предимствата на енергийната ефективност и да получат целеви кредити
          и безвъзмездна финансова помощ чрез мрежата на български търговски
          банки – партньори на програмата.
        </p>
      </div>
      <div class="image">
        <v-img
          max-height="120"
          :src="require('../assets/home/reecl.png')"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import OfferCard from '../components/OfferCard';

export default {
  name: 'Home',
  components: {
    OfferCard,
  },
  data: () => ({
    carouselItems: [],
    offers: [
      {
        name: 'Montaj',
        image: require('../assets/home/instalation.jpg'),
        title: 'Монтаж и Сервиз',
        subTitle:
          'Генерален сервиз за Пловдив и региона на Грейко инженеринг и Виктория 05.',
        description:
          'Разполагаме с оборудвани и квалифицирани екипи готови за монтаж.',
      },
      {
        name: 'Otoplenie',
        image: require('../assets/home/warming.jpg'),
        title: 'Пелетно отопление',
        subTitle:
          'През последните години пелетното отопление се наложи на пазара като най-комфортния начин за отопление на битови къщи.',
        description:
          'При нашия монтаж и последващ сервиз гарантираме на клиентите липса на проблем.',
      },
      {
        name: 'Kolektori',
        title: 'Слънчеви колектори',
        image: require('../assets/home/sun.jpg'),
        subTitle:
          'Ние от ДК-Терма имаме дългогодишен опит в поставянето на слънчеви колектори',
        description:
          'Затова можем да изградим не аварираща инсталация, която осигурява несравним комфорт за клиента.',
      },
      {
        name: 'ViK',
        title: 'ВиК системи',
        image: require('../assets/home/vik.jpg'),
        subTitle:
          'Фирмата има на разположение голям брой квалифицирани работници.',
        description:
          'Благодарение на което, можем да разпределим трудовия ресурс според спецификата на обектите.',
      },
      {
        name: 'PodovoOtoplenie',
        title: 'Подово отопление',
        image: require('../assets/home/podovo.jpg'),
        subTitle:
          'Изборът на подово отопление ще Ви предостави чудесен комфорт през зимата.',
        description: 'Ние ще Ви го монтираме бързо и качествено.',
      },
      {
        name: 'PodovoOhlajdane',
        title: 'Сградно отопление и охлаждане',
        image: require('../assets/home/podovo_ohlajdane.jpg'),
        subTitle: 'За енергийна ефективност и комфорт през четирите сезона.',
        description: 'За Вас предлагаме сградно отопление и охлаждане.',
      },
    ],
  }),
  mounted() {
    this.importAll(require.context('../assets/home_sliders', true));
    setTimeout(() => {
      if (this.$route.query && this.$route.query.offers) {
        const el = this.$el.querySelector('.offers-container');
        if (el) {
          const y = el.getBoundingClientRect().top + window.pageYOffset - 50;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }
    }, 0);
  },
  methods: {
    openPage(e) {
      this.$router.push({ name: e });
    },
    importAll(r) {
      r.keys().forEach((key) => this.carouselItems.push(r(key)));
    },
  },
};
</script>
<style lang="scss" scoped>
.offers-container {
  display: flex;
  justify-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
}

.divider {
  width: 100%;
  height: 5px;
  background-color: #083452;
}

.reecl {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .text {
    width: 85%;
  }

  .image {
    width: 15%;
  }
}

@media screen and (max-width: 650px) {
  .offers-container {
    display: block;
  }
  .reecl {
    .text,
    .image {
      width: 100%;
    }
  }
}
</style>
