import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Kolektori from '../views/Kolektori'
import Otoplenie from '../views/Otoplenie'
import PodovoOtoplenie from '../views/PodovoOtoplenie'
import PodovoOhlajdane from "../views/PodovoOhlajdane";
import ViK from '../views/ViK'
import Montaj from '../views/Montaj'
import Partniori from '../views/Partniori'
import About from '../views/About'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/montaj-i-serviz',
        name: 'Montaj',
        component: Montaj
    },
    {
        path: '/slunchevi-kolektori',
        name: 'Kolektori',
        component: Kolektori
    },
    {
        path: '/peletno-otoplenie',
        name: 'Otoplenie',
        component: Otoplenie
    },
    {
        path: '/podovо-otoplenie',
        name: 'PodovoOtoplenie',
        component: PodovoOtoplenie
    },
    {
        path: '/ViK',
        name: 'ViK',
        component: ViK
    },
    {
        path: '/sgradno-otoplenie-i-ohlajdane',
        name: 'PodovoOhlajdane',
        component: PodovoOhlajdane
    },
    {
        path: '/partniori',
        name: 'Partners',
        component: Partniori
    },
    {
        path: '/kontakti',
        name: 'Contacts',
        component: About
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})
router.beforeEach((to, from, next) => {
    const found = routes.find(route => route.name === to.name);
    if (found) {
        next();
    } else {
        next({name: 'Home'});
    }
});

export default router
