<template>
    <div class="podovo-otoplenie">
        <gallery :dialog="dialog" :items="images" :selected-index="selectedIndex" @change-index="changeIndex"
                 @close-dialog="closeDialog"></gallery>
        <div class="text-title pa-6">
            <h1 class="heading">Подово отопление</h1>
            <h2 class="subtitle-1">Подовото отопление представлява оптималното решение за комфорт и икономия. Нашата фирма Ви гарантира 100% качество и ефективност.</h2>
        </div>
        <div v-if="images" class="gallery pa-6">
            <v-row>
                <v-col v-for="(image,index) in images" :key="index" class="d-flex child-flex"
                       :cols="2">
                    <v-img :src="image"
                           :lazy-src="image"
                           aspect-ratio="1"
                           class="grey lighten-2 image-item"
                           @click="imageClicked(index)">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0"
                                   align="center"
                                   justify="center">
                                <v-progress-circular indeterminate color="#083452"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import Gallery from "../components/Gallery";

    export default {
        name: "PodovoOtoplenie",
        components: {
            Gallery
        },
        data: () => ({
            images: [],
            dialog: false,
            selectedIndex: 0,
        }),
        mounted() {
            this.importAll(require.context('../assets/podovo_otoplenie', true));
        },
        methods: {
            importAll(r) {
                r.keys().forEach(key => (this.images.push(r(key))));
            },
            imageClicked(index) {
                this.selectedIndex = index;
                this.dialog = true;
            },
            closeDialog() {
                this.selectedIndex = 0;
                this.dialog = false;
            },
            changeIndex(i) {
                this.selectedIndex = i;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .image-item {
        cursor: pointer;
    }
</style>
