<template>
    <div class="about">
        <GmapMap
                :center="center"
                :zoom="zoom"
                map-type-id="terrain"
                style="width: 100%; height: 100%;">
            <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                            @closeclick="infoWinClose">
            </GmapInfoWindow>
            <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    @click="toggleInfoWindow(m,index)"/>
        </GmapMap>
    </div>
</template>
<script>
    export default {
        name: 'About',
        data: () => ({
            defaultCenter: {
                lat: 42.3451331,
                lng: 24.6547678
            },
            center: {
                lat: 42.3451331,
                lng: 24.6547678
            },
            zoom: 10,
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            infoOptions: {
                content: '',
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            markers: [
                {
                    position: {
                        lat: 42.157942,
                        lng: 24.743153
                    },
                    infoText: `Централен магазин – Пловдив, ул. Васил Левски 12 <a target="_blank" href="https://www.google.com/maps/place/ДК+Терма+ЕООД/@42.1580032,24.7430812,20.22z/data=!4m5!3m4!1s0x0:0x99f1dd48c8f4c732!8m2!3d42.1578248!4d24.7433257?hl=bg">отвори в google maps</a>`
                },
                {
                    position: {
                        lat: 42.5055113,
                        lng: 24.7091725
                    },
                    infoText: 'Офис – Хисаря, ул. генерал Гурко 25'
                }]
        }),
        methods: {
            toggleInfoWindow: function (marker, idx) {
                this.infoWindowPos = marker.position;
                this.center = marker.position;
                this.zoom = 15;
                this.infoOptions.content = marker.infoText;
                if (this.currentMidx === idx) {
                    this.infoWinOpen = !this.infoWinOpen;
                } else {
                    this.infoWinOpen = true;
                    this.currentMidx = idx;
                }
            },
            infoWinClose() {
                this.infoWinOpen = false;
                this.zoom = 10;
                this.center = this.defaultCenter;
            }
        }
    }
</script>
<style>
.about{
    height: 100%;
    width: 100%;
}
</style>
