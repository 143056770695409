<template>
    <v-card flat color="#ffffff" light max-width="450">
        <v-img class="dark align-end"
               height="250px"
               contain
               :src="src">
            <h2 class="card-title text-center">{{ title }}</h2>
        </v-img>

        <h4 class="subtitle-1 pl-2">
            {{ subTitle }}
        </h4>

        <v-card-text class="text--primary pa-0 pl-2">
            <div>{{ description }}</div>
        </v-card-text>

        <v-card-actions class="pl-0">
            <v-btn color="#ff9800" class="" text @click="offerClicked">Вижте още</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "OfferCard",
        props: {
            name: String,
            title: String,
            subTitle: String,
            description: String,
            src: String
        },
        methods: {
            offerClicked() {
                this.$emit('read-more', this.name)
            }
        }
    }
</script>

<style scoped>
    .card-title {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
    }
</style>
