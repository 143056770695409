<template>
    <div class="partniori">
        <v-card v-for="(item,index) in partners" :key="index" tile>
            <div class="main-wrapper pa-4">
                <div class="left-side">
                    <h1 class="pl-4 ma-0 headline">
                        {{item.name}}
                    </h1>
                    <v-card-text>{{item.description}}</v-card-text>
                    <div>
                        <v-btn color="orange" @click="openPage(item.link)" text>Към сайта</v-btn>
                    </div>
                </div>
                <div class="right-side">
                    <v-img width="300" contain :src="item.logo">
                    </v-img>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: "Partniori",
        data: () => ({
            partners: [
                {
                    name: 'ГРЕЙКО ИНЖЕНЕРИНГ ООД',
                    description: '„Грейко инженеринг“ ООД е с основна дейност проектиране и производство на пелетни съоръжения.',
                    link: 'https://www.greyko.com/',
                    logo: require('../assets/partniori/Greyko.png')
                },
                {
                    name: 'ВИКТОРИЯ 05',
                    description: 'Виктория 05 е производител на висококачествени уреди на твърдо гориво – пелетни камини и котли, камини, готварски печки, камери за вграждане,каменни облицовки, димоотводни тръби.',
                    link: 'https://v05.bg',
                    logo: require('../assets/partniori/Viktoria.png')
                },
                {
                    name: 'ЮРОКОМ 2000 ООД',
                    description: 'Фирмата е комплексен доставчик на системи за водопровод, канализация и отопление за изграждане на сградни инсталации, инфраструктурни и промишлени обекти.',
                    link: 'http://eurocom2000.net/',
                    logo: require('../assets/partniori/Urokom.png')
                },
                {
                    name: 'БГ Майстор',
                    description: 'Фирмата предлага продукти в областта на банята, водопровода, канализацията и отоплението.',
                    link: 'http://bg-maistor.com/',
                    logo: require('../assets/partniori/BGMaistor.jpg')
                },
                {
                    name: 'ТЕРМОДИЗАЙН ТОТАЛ',
                    description: 'Фирмата предлага всичко необходимо за изграждане на вашата отоплителна инсталация,водопровод или климатична инсталация,инсталация за добиване на гореща вода за санитарни нужди посредством слънчеви колектори или комбинирано с котел за отопление.',
                    link: 'http://www.thermodesigntotal.com/',
                    logo: require('../assets/partniori/thermodesigntotal.jpg')
                }
            ]
        }),
        methods: {
            openPage(link) {
                window.open(link, '_blank');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .main-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: left;
        flex-wrap: wrap;

        .left-side {
            width: 60%;
        }
        .right-side{
            display: flex;
        }
    }
    @media screen and (max-width: 650px) {
        .main-wrapper .left-side {
            width: 100%;
        }
    }
</style>
