<template>
  <v-app>
    <v-app-bar app light>
      <router-link v-if="$vuetify.breakpoint.width > 700" to="/home">
        <div class="logo-image-container">
          <v-img max-height="40" contain alt="Logo" src="./assets/logo.png" />
        </div>
      </router-link>
      <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
      <div class="menu-items">
        <v-btn @click="openOffers()" plain :class="menuItemsClass">
          Услуги
        </v-btn>
        <router-link class="text-decoration-none" to="partniori">
          <v-btn plain :class="menuItemsClass">
            Партньори
          </v-btn>
        </router-link>
        <router-link class="text-decoration-none" to="kontakti">
          <v-btn :class="menuItemsClass" plain>
            Контакти
          </v-btn>
        </router-link>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer class="app-footer" color="#083452">
      <div class="main-container">
        <v-card flat color="#083452" dark>
          <v-card-title>Контакти</v-card-title>
          <v-card-text>
            <div class="contact-container">
              <v-icon class="icon mr-1 pr-2">
                mdi-map-marker
              </v-icon>
              <div class="content">
                <div class="heading">Пловдив ул. Васил Левски 12</div>
                <div class="heading">Хисаря ул. генерал Гурко 25</div>
              </div>
            </div>
          </v-card-text>
          <v-card-text>
            <div class="contact-container">
              <v-icon class="icon mr-1 pr-2">
                mdi-phone
              </v-icon>
              <div class="content">
                <div class="heading">
                  <a href="tel:+359889608441">0889 60 84 41</a>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-text>
            <div class="contact-container">
              <v-icon class="icon mr-1 pr-2">
                mdi-at
              </v-icon>
              <div class="content">
                <div class="heading">
                  <a href="mailto:dimitarkisiov@gmail.com"
                    >dimitarkisiov@gmail.com</a
                  >
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card flat color="#083452" dark>
          <v-card-title>Социални мрежи</v-card-title>
          <v-card-text>
            Общувайте с нас и чрез социалните мрежи
          </v-card-text>
          <div class="social-buttons ml-4">
            <v-btn color="#48a9e0" @click="openFacebook" class="text--white">
              <v-icon class="icon">
                mdi-facebook
              </v-icon>
            </v-btn>
          </div>
        </v-card>
        <v-card flat color="#083452" dark>
          <v-card-title>Работно време</v-card-title>
          <v-list two-line color="#083452">
            <v-list-item v-for="(day, index) in workDays" :key="index">
              <v-list-item-content class="pa-0 ma-0">
                <v-list-item-title v-text="day.title"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="day.subTitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
      <p class="copyright">
        Copyright © 2016 - {{ year }} ДК Терма ЕООД. Всички права запазени.
      </p>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    workDays: [
      {
        title: 'Понеделник до Петък',
        subTitle: '09:00 - 17:00',
      },
      {
        title: 'Събота и Неделя',
        subTitle: 'Почивен ден',
      },
    ],
    year: new Date().getFullYear() || '2023',
  }),
  computed: {
    menuItemsClass() {
      if (this.$vuetify.breakpoint.width <= 300) {
        return 'x-small';
      } else if (
        this.$vuetify.breakpoint.width > 300 &&
        this.$vuetify.breakpoint.width <= 600
      ) {
        return 'small';
      }
      return '';
    },
  },
  methods: {
    openFacebook() {
      window.open('https://www.facebook.com/dimitar.kisiov', '_blank');
    },
    openOffers() {
      if (this.$route.name === 'Home') {
        const el = this.$el.querySelector('.offers-container');
        if (el) {
          const y = el.getBoundingClientRect().top + window.pageYOffset - 50;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      } else {
        this.$router.push({ name: 'Home', query: { offers: 'true' } });
      }
    },
  },
};
</script>

<style lang="scss">
.small {
  height: 28px !important;
  min-width: 50px !important;
  padding: 0 12.4444444444px !important;
  font-size: 0.75rem !important;
}
.x-small {
  height: 20px !important;
  min-width: 36px !important;
  padding: 0 8.8888888889px !important;
  font-size: 0.625rem !important;
}
.app-footer {
  .main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    .contact-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .icon {
        width: 10%;
        border-right: 1px solid #fff;
      }

      .content {
        width: 90%;
      }
    }

    .social-buttons {
      display: flex;
    }

    a {
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
    }
  }
  .copyright {
    display: flex;
    width: 100%;
    justify-content: center;
    color: white;
    padding: 0 16px;
    text-align: center;
  }
}
</style>
