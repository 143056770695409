<template>
    <div class="gallery">
        <v-dialog class="overflow-hidden" overlay-opacity="1" overlay-color="#e3e3e3" v-model="dialog"
                  @click:outside="closeModal">
            <v-card flat tile>
                <v-toolbar collapse rounded dense absolute>
                    <v-btn color="#000000" icon @click="closeModal">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
            </v-card>
            <v-card class="mx-auto ma-4 card" max-width="375">
                <v-img height="66vh" contain :src="items[selectedIndex]"></v-img>
            </v-card>
            <div class="images-list">
                <v-btn light @click="scrollLeft">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <div class="cards-list" ref="cardsList">
                    <v-card :class="`${selectedIndex === index ?'selected ' : ''} ma-2`" v-for="(image,index) in items"
                            :key="index" max-width="100" flat
                            @click="changeIndex(index)">
                        <v-img width="100" height="120" :src="image"></v-img>
                    </v-card>
                </div>
                <v-btn light @click="scrollRight">
                    <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "Gallery",
        props: {
            selectedIndex: Number,
            items: Array,
            visibility: Boolean,
            dialog: Boolean,
        },
        mounted() {
            if(this.$refs.cardsList){
                let element = this.$refs.cardsList.querySelector('.selected');
                if (element) {
                    element.scrollIntoView();
                }
            }
        },
        methods: {
            changeIndex(i) {
                this.$emit('change-index', i);
            },
            closeModal() {
                this.$emit('close-dialog');
            },
            scrollLeft() {
                let element = this.$refs.cardsList;
                if (element && this.selectedIndex > 0) {
                    this.$emit('change-index', this.selectedIndex - 1);
                    element.querySelector('.selected').previousElementSibling.scrollIntoView();
                }
            },
            scrollRight() {
                let element = this.$refs.cardsList;
                if (element && this.selectedIndex < this.items.length - 1) {
                    this.$emit('change-index', this.selectedIndex + 1);
                    element.querySelector('.selected').nextElementSibling.scrollIntoView();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .close-button {
        background-color: red;
    }

    .selected {
        box-shadow: 0 0 13px #267ce9 !important;
    }

    .images-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .cards-list {
            display: flex;
            flex-direction: row;
            overflow: auto;
            max-width: 80%;
        }
    }
</style>
