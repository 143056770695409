import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        region: 'BG',
        language: 'bg',
        key: 'AIzaSyANM1Ff1-y_0J-gZR7nhf5XUBdWPuWczWc',
        libraries: 'places', // This is required if you use the Autocomplete plugin
    }
});

Vue.config.productionTip = false
new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');
